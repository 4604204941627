<template>
    <div class="attestation-block h-100 d-flex flex-column" v-if="testing_on">
        <div class="attestation-block__title">Тестирование запущено
            <time v-if="testingDateUpdate">{{ testingDateUpdate | moment('D MMMM YYYY') }} в {{ testingDateUpdate | moment('HH:mm:ss') }}</time>
        </div>
        <div class="attestation-block__hint">Если вы не хотите больше проводить тестирование, то
            нажмите на кнопку
        </div>
        <div class="attestation-block__timer">
            <div class="attestation-block__subtitle">Таймер тестирования</div>
            <div class="attestation-block__group">
                {{form.minutes}} {{ functions.num2str(form.minutes, phrases.minutes) }},
                {{form.seconds}} {{ functions.num2str(form.seconds, phrases.seconds) }}
            </div>
        </div>

        <div class="attestation-block__buttons">
            <a
                class="btn btn-rounded btn-alt-secondary attestation-close"
                href="javascript:void(0);"
                @click="stopTesting"
            >
                Завершить тестирование
            </a>
        </div>
    </div>
    <div class="attestation-block h-100 d-flex flex-column" v-else>
        <div class="attestation-block__title">Запуск тестирования</div>
        <div class="attestation-block__hint">
            Нажмите на кнопку, чтобы запустить тестирование сотрудников по набору добавленных вами вопросов
            на знание правил базы знаний
        </div>
        <ValidationObserver v-if="changeForm" class="attestation-block__timer" tag="form" ref="observer" @submit.prevent="onSubmit">
            <div class="attestation-block__subtitle">Установите таймер тестирования</div>
            <ValidationProvider tag="div" vid="minutes" v-slot="{errors}" :rules="`required|not_null|numeric`" class="attestation-block__form">
                <div class="attestation-block__group">
                    <label for="id_minutes">Минут</label>
                    <number-input
                        onkeypress="return event.key === 'Enter' || (Number(event.key) >= 0 && Number(event.key) <= 9)"
                        v-model="form.minutes"
                        id="id_minutes"
                        style="height: 40px; width: 60px;"
                        :min="1"
                        :max="999"
                    />
                </div>
                <div class="attestation-block__group mr-20 ml-5">
                    <label for="id_seconds">Секунд</label>
                    <number-input
                        onkeypress="return event.key === 'Enter' || (Number(event.key) >= 0 && Number(event.key) <= 9)"
                        v-model="form.seconds"
                        id="id_seconds"
                        style="height: 40px; width: 60px;"
                        :min="0"
                        :max="59"
                    />
                </div>
                <button class="v-btn v-btn--transparent" style="border: 2px solid #FBC04F">
                    Сохранить
                </button>
                <span class="v-error animated fadeInDown" v-if="errors.length">
                        {{errors[0]}}
                </span>
            </ValidationProvider>
        </ValidationObserver>
        <div v-else class="attestation-block__timer">
            <div class="attestation-block__subtitle">Таймер тестирования</div>
            <div class="attestation-block__group flex-row align-items-center">
                {{form.minutes}} {{ functions.num2str(form.minutes, phrases.minutes) }},
                {{form.seconds}} {{ functions.num2str(form.seconds, phrases.seconds) }}
                <button @click="changeForm = true" class="v-btn v-btn--transparent ml-20" style="border: 2px solid #FBC04F">
                    Изменить
                </button>
            </div>
        </div>
        <div class="attestation-block__buttons">
            <a class="btn btn-rounded btn-primary-dark attestation-start ml-auto" href="javascript:void(0);"
               @click="startTesting">
                Запустить тестирование
            </a>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    import functions from "assets/js/functions";

    export default {
        name: 'TestControlWidget',
        props: {
            testing_on: {
                type: Boolean,
                required: false
            },
            testing_date: {
                required: false
            }
        },
        created: function () {
            this.loadData()
        },
        computed: {
            testingDateUpdate() {
                if (this.testingDate) {
                    return this.testingDate
                } else {
                    return this.testing_date
                }
            }
        },
        data() {
            return {
                functions,
                testingDate: null,
                changeForm: false,
                form: {
                    minutes: 20,
                    seconds: 0,
                },
                phrases: {
                    minutes: ['минута', 'минуты', 'минут'],
                    seconds: ['секунда', 'секунды', 'секунд'],
                },
            }
        },
        methods: {
            async onSubmit() {
                let isValid = await this.$refs.observer.validate();
                if (isValid) {
                    try {
                        const request = session.post(`/api/v1/testing-control-widget/set_time/`, this.form);
                        let response = await request;
                        this.changeForm = false;
                        this.$swal({
                                title: 'Отлично!',
                                text: 'Таймер успешно установлен!',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400,
                            });
                    } catch (err) {
                        this.$swal({
                            title: 'Ошибка!',
                            text: 'При обработке запроса произошла ошибка на сервере',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1400,
                        });
                    }
                }
            },
            async loadData() {
                try {
                    const request = session.get(`/api/v1/testing-control-widget/set_time/`);
                    let response = await request;
                    this.form = response.data
                } catch (err) {

                }
            },
            async startTesting() {
                this.$swal({
                    customClass: {
                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                    },
                    title: 'Запуск тестирования',
                    text: 'Вы хотите запустить тестирование?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Да, запустить!',
                    cancelButtonText: 'Отменить',
                }).then(value => {
                    if (value.value) {
                        let swal = this.$swal({
                            title: 'Обработка...',
                            icon: 'info',
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                        });
                        // выполнение
                        session
                            .post(`/api/v1/testing-control-widget/start_testing/`)
                            .then(response => {
                                swal.close();
                                this.testingDate = response.data.testing_date;
                                this.$emit('testing_on');
                                this.$swal({
                                    title: 'Отлично!',
                                    text: 'Тестирование запущено',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                            })
                            .catch((err) => {
                                this.$swal({
                                    title: 'Ошибка!',
                                    text: 'При обработке запроса произошла ошибка на сервере',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                            });
                    }
                }, reason => {
                    // отказ
                });
            },
            async stopTesting() {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    this.$swal({
                        customClass: {
                            confirmButton: 'btn btn-lg btn-alt-success m-5',
                            cancelButton: 'btn btn-lg btn-alt-danger m-5'
                        },
                        title: 'Завершение тестирования',
                        text: 'Вы хотите завершить тестирование?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Да, завершить!',
                        cancelButtonText: 'Отменить',
                    }).then(value => {
                        if (value.value) {
                            let swal = this.$swal({
                                title: 'Обработка...',
                                icon: 'info',
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                            });
                            // выполнение
                            session
                                .post(`/api/v1/testing-control-widget/stop_testing/`)
                                .then(response => {
                                    swal.close();
                                    this.$emit('testing_on');
                                    this.$swal({
                                        title: 'Отлично!',
                                        text: 'Тестирование завершено',
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1400,
                                    });
                                })
                                .catch((err) => {
                                    this.$swal({
                                        title: 'Ошибка!',
                                        text: 'При обработке запроса произошла ошибка на сервере',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timer: 1400,
                                    });
                                });
                        }
                    }, reason => {
                        // отказ
                    });
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.attestation-block {
    &__timer {
        margin-top: auto;
        margin-bottom: auto;
    }
    &__group {
        display: flex;
        flex-direction: column;
    }
    &__subtitle {
        font-weight: 600;
    }
    &__form {
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin-top: 10px;
        label {
            color: #9F9F9F;
        }
        @media (max-width: 1366px) {
            flex-wrap: wrap;
            .v-btn {
                margin-top: 20px;
            }
        }
    }
    &__buttons {
        margin-top: auto;
        @media (max-width: 1366px) {
            margin-top: 20px !important;
            .btn {
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
</style>
